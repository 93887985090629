const routes = [
  {
    path: '',
    name: '1',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: '',
        name: '1',
        // component: () => import(/* webpackChunkName: "Home" */ '@/view/dashboard/Index.vue'),
        component: () => import(/* webpackChunkName: "Home" */ '@/view/pages/dashboard/Index.vue'),
      },
    ],
  },
  
];

export default routes;
